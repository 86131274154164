<template>
  <div class="suboridinate">
    <van-config-provider>
      <van-nav-bar :title="pageTitle" :border="false">
        <template #left>
          <van-icon name="arrow-left" size="20" @click="goBack" />
          <span v-if="uidList.length > 0" @click="showUpper">{{$t('button.back')}}</span>
        </template>
      </van-nav-bar>
    </van-config-provider>
    <div class="order-list">
      <van-list v-if="dataList.length >0">
        <div class="journal-block white" v-for="item in dataList" :key="item.uid" @click="showSubordinate(item)">
          <van-cell-group :border="false">
            <van-cell :title="$t('field.account')" :value="item.username" class="journal-title" value-class="journal-sn"/>
            <div class="journal-detail">
              <van-cell :title="$t('field.teamTurnover')" :value="item.orderNum" :border="false" class="journal-detail-row" />
              <van-cell :title="$t('field.teamOrders')" :value="item.runningVolumeStr" :border="false" class="journal-detail-row" />
              <van-cell :title="$t('field.lastTradingTime')" :value="item.lastTradingTimeStr || '-'" :border="false" class="journal-detail-row" />
            </div>
          </van-cell-group>
        </div>
      </van-list>
      <div v-else>
        <van-empty :description="$t('common.noData')"></van-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import i18n from '@/assets/lang/index';
import { getSubordinateList } from "@/api";

export default {
  setup() {
    const dataList = ref([]);
    return {
      dataList,
    };
  },
  
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      uidList: [],
    };
  },
  
  mounted() {
    this.fetchOrderList();
  },

  methods: {
    fetchOrderList() {
      let uid = undefined;
      if (this.uidList.length > 0) {
        uid = this.uidList[this.uidList.length - 1];
      }
      getSubordinateList({ uid }).then(res => {
        this.dataList = res.data.lowerSubtotal || [];
      });
    },
    showSubordinate(user) {
      if(user.subNum === 0) {
        this.$toast({ type: 'warning', message: i18n.global.t('notify.noSubordinates') });
        return false;
      }
      this.uidList.push(user.uid);
      this.fetchOrderList();
    },
    showUpper() {
      this.uidList.pop();
      this.fetchOrderList();
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.theme1 .suboridinate {
  .journal-block {
    .journal-title {
      font-size: 15px;
      color: #a5a5a5;
    }
    .journal-detail {
      padding: 10px 0;
    }
    .journal-detail-row {
      padding: 3px 0;
      font-size: 12px;
      border-bottom: none;
    }
    .van-cell__title {
      flex: 3;
      font-weight: 600;
     }
    .van-cell__value { flex: 2; color: #333; }
  }
}
</style>
